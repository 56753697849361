import React from 'react';
import './App.scss';
import Slideshow from './components/Slideshow/Slideshow';
import OptInForm from './components/OptInForm/OptInForm';

function App() {
  const endpoint = 'https://script.google.com/macros/s/AKfycbzC-T4DS97jd-2xS65CE4sFLKUNqPfaURV5HBfFi4ik45dk9l4JF7xwhRF2W8-YAQHw/exec';
  const handleSubmit = (phoneNumber) => {
    return fetch(endpoint, {
      redirect: "follow",
      method: 'POST',
      body: JSON.stringify({ phone_number: phoneNumber })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error;
    });
  };

  return (
    <div>
      <Slideshow />
      <OptInForm onSubmit={handleSubmit} />
    </div>
  );
}

export default App;