import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './OptInForm.scss';
import hashtagIcon from '../../assets/icons/hashtag.svg';
import Spinner from 'react-bootstrap/Spinner';

const OptInForm = ({ onSubmit }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [phoneError, setPhoneError] = useState(null);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const formattedPhoneNumber = formatPhoneNumber(value);
    setPhoneNumber(formattedPhoneNumber);

    const numericPhoneNumber = formattedPhoneNumber.replace(/[^\d]/g, '');
    if (numericPhoneNumber.length === 10) {
      setPhoneError(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const numericPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
    if (numericPhoneNumber.length === 10) {
      setPhoneError('');
      setIsError(false);
  
      onSubmit(phoneNumber)
        .then((data) => {
          setLoading(false);
          setMessage('Thank You for Subscribing!');
          setPhoneNumber('');
        })
        .catch((error) => {
          setLoading(false);
          setIsError(true);
          setMessage('Something went wrong. Please try again later.');
        });
    } else {
      setLoading(false);
      setIsError(true);
      setPhoneError(
        <>
          Please enter a 10-digit phone number.<br />
          <span>e.g. (123) 456-7890</span>
        </>
      );
    }
  };

  return (
    <div className="subscribe-wrap">
      
      <div>
        <h1>Unlock the Extraordinary</h1>
        <h2>Embark on a journey of exclusive treats and secret soirées – your VIP pass is a tap away!</h2>
      </div>

      {loading ? (
        <div className='spinner-wrapper'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> 
      ) : message ? (
        <div className={isError ? 'alert alert-danger' : 'alert alert-success'} role="alert">
          {message}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <img src={hashtagIcon} alt="hashtag icon" />
          <input
            type="tel"
            className="form-control"
            id="phone-number"
            placeholder="(123) 456-7890"
            required
            value={phoneNumber}
            onChange={handleInputChange}
          />
          <span className={`underline ${phoneError ? 'underline-alert-danger' : ''}`} />
          <button type="submit" className="btn btn-primary">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Subscribe
          </button>
          {phoneError && <div className="alert alert-danger" role="alert">{phoneError}</div>}
        </form>
      )}
      
      <div>
        <p className='disclaimer'>By providing your phone number above, and pressing the "Subscribe" button, you agree to receive automated text messages.</p>
        <p className='disclaimer'>Message and data rates may apply.<br/>Reply STOP to unsubscribe.</p>
      </div>
      
    </div>
  );
};

export default OptInForm;