import React from 'react';
import './Slideshow.scss';

const Slideshow = () => {
  return (
    <ul className="slideshow">
      <li><span>Image 01</span></li>
      <li><span>Image 02</span></li>
      <li><span>Image 03</span></li>
      <li><span>Image 04</span></li>
    </ul>
  );
};

export default Slideshow;